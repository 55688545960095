import {React, useState} from 'react';
import Project from './project';

import imgone from './projects/1.png'
import imgwedding from './projects/wedding_project.png'
import gifwedding from './projects/wedding_project.gif'


import { useResizeDetector } from 'react-resize-detector';

function Projects({}) {
    const { width, height, ref } = useResizeDetector();

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1000: { items: 3 },
        1553: { items: 4 },
    };

    const projects_dict = [
        {
            "name": "Wedding Memory Portal",
            "tools": ["React", "Node.js", "Axios"],
            "description": `This portal, designed for a wedding event, allows guests to upload their photos and videos through NFC coasters. The portal ensures efficient and secure collection of wedding memories, enhancing the experience for guests and hosts alike.`,
            "preview_image": imgwedding,
            "animated_image": gifwedding,
            "link": "/wedding"
        },
        {
            "name": "Lecture Speedrunner",
            "tools": ["JavaScript", "HTML5", "CSS3"],
            "description": `Lecture Speedrunner is a useful Chrome extension designed to adjust video playback speeds on various websites. Ideal for students and professionals, it allows users to speed up or slow down video content to suit their viewing preferences. The extension is simple to use, enhancing the efficiency of learning and content consumption.`,
            "preview_image": imgone,
            "link": 'https://chromewebstore.google.com/detail/lecture-speedrunner/pmeblkkjhjbkcjpfegienogpgajafngp'
        }
    ]

    return (
        <div className='projects' id='projects' ref={ref}>
            <h1 style={{fontSize : ((width / 32 + 10) > 55) ? 55 : width / 32 + 10}}>What have I been up to lately?</h1>
            {projects_dict.map(function(object, i){
                return <Project project={projects_dict[i]} />;
            })}
        </div>
    );
}

export default Projects;