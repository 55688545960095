import React from 'react';

function About({}) {

    return (
        <div className='aboutMe' id='aboutMe'>
            <h1>Hi, I’m Ralph!</h1>
            <p>
                I'm a Data Analyst and Developer with a knack for turning complex data into business insights and creating engaging, functional, user-friendly web experiences. My journey has taken me through the vibrant corridors of financial services and the bustling atmosphere of the restaurant industry, where I've honed a unique blend of technical know-how and real-world insights. 
            </p>

            {/* {Insert section here based off of: https://mattfarley.ca/
            Data Analysis

            Frontend Developer

            Backend Developer

            } */}
                
            
            <p>
                My tool belt is packed with languages and frameworks like Python, JavaScript, React.js, and Node.js, SQL, so whether you're in need of someone who can dissect data to uncover hidden gems or craft a sleek, responsive application / website, I'm your guy. Let's chat, collaborate, and <b>create something awesome together!</b>
            </p>
        </div>
    );
}

export default About;