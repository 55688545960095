import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt } from '@fortawesome/free-solid-svg-icons'
import { useSpring, animated as a, config } from "react-spring";

import logo from '../Logo.gif'
function Header({scrollToAboutMe, scrolltoProjects, scrolltoContact}) {


  const contentProps = useSpring({
    opacity: 1,
    marginTop: 0,
    height: "100%",
    config: config.wobbly
  });

  return (
    <div className="header">
        {/* TODO: Link nav's to scrolling position's */}
        {/* TODO: Fix variable scaling for mobile */}
        <Container fluid style={{height:"100%"}}>
          <Row>
            
            <Col id="nav" className="d-flex flex-column justify-content-end">

              <ul className="align-right">
                  <li className="about"><a href="#" onClick={scrollToAboutMe}>about</a></li>
                  <li className="projects"><a href="#" onClick={scrolltoProjects}>projects</a></li>
                  <li className="contact"><a href="#" onClick={scrolltoContact}>get in touch</a></li> 
              </ul>
  
            </Col>
          </Row>
        </Container>

    </div>
  );
}

export default Header;
