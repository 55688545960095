import React, { useState, useEffect } from 'react'
import Typewriter from 'typewriter-effect';

function Hero({}) {


  const [animationDone, setAnimationDone] = useState(false)
  

  return (
    <div className="hero">
        {/* TODO: Fix variable scaling for mobile */}
        {/* TODO: Implement heroDescription type-writer logic */}
 
      <div className = "heroText" >ralph roque</div>
      
      <div className= 'heroDescription'>
      
      {!animationDone ? 
        <Typewriter
          options={{
            autoStart: false,
            delay: 35,
          }}
          onInit={(typewriter) => {
              // setting the typeWriterStatus here calls on the header elements to animate in. 
              typewriter
              .typeString('back-end developer')
                .pauseFor(600)
                .deleteAll()
                .typeString('front-end developer')
                .pauseFor(600)
                .deleteAll()
                .typeString('business oriented full-stack developer')
                .start()
                .callFunction(() => {
                  setAnimationDone(true)
                })
            }
          }
        /> :
        <>business oriented full-stack developer</> 
    }

      </div>
    </div>
  );
}

export default Hero;
