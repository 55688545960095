import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useResizeDetector } from 'react-resize-detector';

function Contact() {
    const { width, height, ref } = useResizeDetector();

    return (
        <div className='contact' id='contact'>
            <h1>Wanna get in touch?</h1>
            <h2>Let's Talk Data and Code</h2>
            <p>Got a project in mind or a data riddle to solve? I’m all ears. I blend a serious knack for data analysis and web development with a laid-back, conversational approach. Think of me as your go-to guy for making sense of numbers or coding up a storm.
            </p>
            <p>
            Whether it's brainstorming over coffee or a formal meeting, I’m here for it. Reach out for collaborations, consultations, or just to bounce ideas around. No matter the challenge, we can tackle it together in a way that’s efficient, effective, and maybe even a bit fun.
            </p>

            <p>
                Can’t wait to hear from you. Let's make your data work wonders and your web projects wow!
            </p>
            <div class="button-wrapper" ref={ref}>
                <a href = "mailto: ralpharoque@gmail.com" target="_blank" rel="noopener noreferrer" style={{fontSize : (width > 495) ? "100px" : width / 5}}><FontAwesomeIcon icon={faEnvelope} fixedWidth /></a>
                <a href = "https://www.linkedin.com/in/ralphroquetoronto/" target="_blank" rel="noopener noreferrer" style={{fontSize : (width > 495) ? "100px" : width / 5}}><FontAwesomeIcon icon={faLinkedin} fixedWidth /></a>
                {/* <a href = "https://github.com/unfyt" target="_blank" rel="noopener noreferrer"  style={{fontSize : (width > 495) ? "100px" : width / 5}}><FontAwesomeIcon icon={faGithub} fixedWidth /></a> */}
            </div>
        </div>
    );
}

export default Contact;