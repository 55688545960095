import React, { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import Stack from 'react-bootstrap/Stack';

function Project({project}) {

    const { width, height, ref } = useResizeDetector();
    const [imgSource, setImgSource] = useState(false)
    
    const imgChangeHandler = () => {
        setImgSource((old) => !old)
    }

    const imgHandler = () => {
        if (project.hasOwnProperty("animated_image") & imgSource){
            return project.animated_image
        } else {
            return project.preview_image
        }
    }

    return (
        <div className='project'>
            <img src={imgHandler()} onMouseEnter={imgChangeHandler} onMouseLeave={imgChangeHandler}></img>
            <div className='projectDetailContainer'>
                <div className='card' ref={ref}>
                    <h1 className='projectName'  style={{fontSize : width / 12}}>{project.name.toLowerCase()}</h1>
                    <p className='projectDetails'>
                        {project.description}
                    </p>
                    <p className='projectTools' style={{fontSize : width / 20}}>{project.tools.map(function(object, i){
                    return `${object.toLowerCase()}${(object == project.tools.at(-1) ? "" : " - ")}`;
                    })}</p>
                    <Stack direction="horizontal" gap={3}>
                        {(project.hasOwnProperty('link') ? <a className="ms-auto me-auto" href={project.link} target="_blank"><button className="projectLink ms-auto" >LIVE SITE</button></a> : <></>)}
                        {(project.hasOwnProperty('github') ? <a className="me-auto" href={project.github} target="_blank"><button className={(width > 600) ? "projectLink"  : "projectLink me-auto"}>GITHUB</button></a> : <></>)} 
                    </Stack>
                </div>
            </div>
        </div>
    );
}

export default Project;