import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import '../App.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import weddingFooter from '../Components/wedding_footer.svg' 
import weddingHeader from '../Components/wedding_header.svg' 
import texture from '../Components/white-paper-texture.webp' 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons'

import { useSearchParams } from "react-router-dom";
import NoSleep from 'nosleep.js';

function FileUploader() {

  const baseURL = 'https://643d-2607-fea8-a500-fa40-f0e6-69d5-3839-ac39.ngrok-free.app'
  const serverURL = `${baseURL}/upload`
  const pingURL = `${baseURL}/ping`
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [activated, setActivated] = useState(true)
  const [uploadStatus, setUploadStatus] = useState("UPLOAD")
  const [uploadProgress, setUploadProgress] = useState(0)

  const [tableNumber, setTableNumber] = useState(0)
  const inputElement = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const [noSleepEnabled, setNoSleepEnabled] = useState(false);
  const noSleep = new NoSleep();

  const toggleNoSleep = async () => {
    if (!noSleepEnabled) {
      // Enable NoSleep asynchronously
      await new Promise((resolve, reject) => {
        noSleep.enable(() => {
          resolve();
        }, (err) => {
          reject(err);
        });
      });
      setNoSleepEnabled(true);
    } else {
      // Disable NoSleep asynchronously
      await new Promise((resolve, reject) => {
        noSleep.disable(() => {
          resolve();
        }, (err) => {
          reject(err);
        });
      });
      setNoSleepEnabled(false);
    }
  };

  const handleIconClick = (event) => {
    inputElement.current.click()
  };

  const handleFileSelect = (event) => {
  
    setUploadProgress(0)
    setUploadStatus("UPLOAD")
    setSelectedFiles(() => {return event.target.files});
  
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setActivated(() => false)
    toggleNoSleep()
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    try {
      setUploadStatus(() => {return "UPLOADING"})
      const url = tableNumber != 0 ? `${serverURL}?table=${tableNumber}` : serverURL 
      
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'ngrok-skip-browser-warning': 'true'
        },
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = ((progressEvent.loaded / progressEvent.total) * 100) / 2
          console.log(uploadPercentage)
          setUploadProgress(() => {return uploadPercentage})
        }
      });

      console.log(response.data);
      setUploadStatus(() => {return "UPLOADED"})
      setUploadProgress(() => {return 100})
      setActivated(() => true)
      setSelectedFiles(() => [])
      inputElement.current.value = "";

    } catch (error) {
      console.error(error);
      setUploadStatus(() => {return "ERROR"})
      setUploadProgress(() => {return 100})
      setActivated(() => true)
    }

    toggleNoSleep()
  };

  const checkServiceOnline = async () => {
    try {
      const response = await axios.get(pingURL, {
        headers: {
          'ngrok-skip-browser-warning': 'true'
        }
      });
      setActivated(true)
    } catch (error) {
      setActivated(false)
    }
  }

  useEffect(() => {
    document.title = "G&R Wedding"
    if(searchParams.get("table")){
      setTableNumber(searchParams.get("table"))
    }

    checkServiceOnline()

  }, []);

  return (
    <div id="weddingPage">

      <img src={texture} className="texture"/>

      {/* Set width of weddingPage to 100% of vw with left right padding of 15% */}
      {/* Justify everything to the center and set widths based off of percentages with max widths set */}
      <Container fluid id='headerImageContainer'>
      <Row>
        <Col></Col>
        <Col xs={12} style={{ maxWidth: "700px" }} className="d-flex align-items-center">
          <img src={weddingHeader} alt="Floral header" id='headerImage'></img>
        </Col>
        <Col></Col>
      </Row>
      </Container>

      <Container fluid>
      <Row>
        <Col></Col>
        <Col xs={10}>
          <h1>GABRIEL & REGINA</h1>
          <h3>Upload your pictures / videos, contribute to our memories</h3>
        </Col>
        <Col></Col>
      </Row>

      <Row>
      <Col></Col>
      <Col xs={12} className="justify-content-center" style={{ maxWidth: "700px" }}>
        <form onSubmit={handleSubmit} id='submitForm' className="d-flex align-items-center justify-content-center">
          <div onClick={handleIconClick} style={{ cursor: "pointer", position: "relative" }}>
            <div style={{ paddingBottom: "", position: "absolute", bottom: "0", right: "-8px", background: "#F1EDE7", borderRadius: "50%", width: "25px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <span style={{ color: "black", fontWeight: "600", fontSize: "13px", fontFamily: "arial" }}>{selectedFiles.length == 0 ? "+" : selectedFiles.length}</span>
            </div>
            <FontAwesomeIcon icon={faImages} size='3x'>
            </FontAwesomeIcon>
          </div>
          <input type="file" name="file" accept="image/*, video/*" multiple onChange={handleFileSelect} ref={inputElement} style={{display: "block", opacity: 0, width: 0, height: 0}}/>
          <button type="submit" id='uploadButton' disabled={selectedFiles.length == 0 || !activated} style={{ display: "flex", alignItems: "center" }}>
            <div style={{position: "absolute", left: "50%",transform: "translateX(-50%)"}}>{uploadStatus}</div>
            <div className="progressBar" style={{width: `${uploadProgress}%`}}></div>
          </button>
        </form>
      </Col>
      <Col></Col>
      </Row>

      <Row>
      <Col></Col>
      <Col xs={12} className="justify-content-center" style={{ maxWidth: "700px" }}>
        <h2>{tableNumber == 1 ? (
        <p>You are uploading to Table #1</p>
      ) : tableNumber > 1 && tableNumber <= 5 ? (
        <p>You are uploading to Table #{tableNumber}</p>
      ) : tableNumber == 10 ? (
        <p>You are uploading to the after party drive.</p>
      ) : (
        <p>Upload server offline. Please contact Ralph for a demo!</p>
      )}</h2>

      </Col>
      <Col></Col>
      </Row>
      </Container>
      

      
      <div id='recentlUploadedContainer'></div>


      <img src={weddingFooter} alt="Floral footer" id="weddingFooter"></img>



    </div>
  );
}

export default FileUploader;